import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { IconContext } from "react-icons";
import { FaGithub } from 'react-icons/fa'
import { SiJavascript, SiCss3, SiHtml5, SiReact } from 'react-icons/si'

import * as styles from '../components/styles.module.scss'

const Projects = () => {
	return (
		<div id='projects' className={styles.portfoliocontainer}>
			<h1>Projects</h1>
			<div className={styles.portfoliocontent}>
				<div className={styles.projectcontainer}>
					<h2>WordSearch-JS</h2>
					<div className={styles.innercontainer}>
						<div className={styles.projectimg}>
							<StaticImage
								src="../images/wordsearchjs.png"
								width={600}
								quality={95}
								formats={['AUTO', 'WEBP', 'AVIF']}
								alt="A Gatsby astronaut"
							/>
						</div>
						<div className={styles.projectcontent}>
							<div className={styles.description}>
								<p>WordSearch-JS is a simple wordsearch generator made in Javascript along with the Canvas API.</p> 
								<p>Select the grid size, fill out the word bank and click "Create New Grid" to generate a random puzzle.</p> 
								<p>With the canvas, users are also able to drag and click to find words.</p> 
							</div>
							<div className={styles.projectlinks}>
								<button onClick={() => window.location = "https://github.com/dkihe/wordsearch-js/"}>Repo</button>
								<button onClick={() => window.location = "https://dkihe.github.io/wordsearch-js/"}>Demo</button>
							</div>
							<div className={styles.iconscontainer}>
								<IconContext.Provider value={{className: styles.projecticons}}>
									<SiJavascript size={35} />
									<SiHtml5 size={35} />
									<SiCss3 size={35} />
								</IconContext.Provider>
							</div>
						</div>
					</div>
				</div>

				<div className={styles.projectcontainer}>
					<h2>Combo Translator</h2>
					<div className={styles.innercontainer}>
						<div className={styles.projectimg}>
							<StaticImage
								src="../images/combotranslator.png"
								width={600}
								quality={95}
								formats={['AUTO', 'WEBP', 'AVIF']}
								alt="A Gatsby astronaut"
							/>
						</div>
						<div className={styles.projectcontent}>
							<div className={styles.description}>
								<p>Combo Translator is an app made in React for the purpose of translating text-based fighting game combo notations into readable images.</p>  
								<p>Simply select your game of choice and copy and paste your notation into the inputbar.</p> 
							</div>
							<div className={styles.projectlinks}>
								<button onClick={() => window.location = "https://github.com/dkihe/combo-translator"}>Repo</button>
								<button onClick={() => window.location = "https://www.combotranslator.com"}>Demo</button>
							</div>
							<div className={styles.iconscontainer}>
								<IconContext.Provider value={{className: styles.projecticons}}>
									<SiJavascript size={35} />
									<SiHtml5 size={35} />
									<SiCss3 size={35} />
									<SiReact size={35} />
								</IconContext.Provider>
							</div>
						</div>
					</div>
				</div>

				<div className={styles.projectcontainer}>
					<h2>Weather App</h2>
					<div className={styles.innercontainer}>
						<div className={styles.projectimg}>
							<StaticImage
								src="../images/weatherapp.png"
								width={600}
								quality={95}
								formats={['AUTO', 'WEBP', 'AVIF']}
								alt="A Gatsby astronaut"
							/>
						</div>
						<div className={styles.projectcontent}>
							<div className={styles.description}>
								<p>A simple weather app made in vanilla javascript that uses the <a href="https://openweathermap.org/api">open weather map api.</a></p>
								<p>Input a city or state to view the current weather for the given location.</p>
							</div>
							<div className={styles.projectlinks}>
								<button onClick={() => window.location = "https://github.com/dkihe/weather-app"}>Repo</button>
								<button onClick={() => window.location = "https://dnkk-weather-app.herokuapp.com/"}>Demo</button>
							</div>
							<div className={styles.iconscontainer}>
								<IconContext.Provider value={{className: styles.projecticons}}>
									<SiJavascript size={35} />
									<SiHtml5 size={35} />
									<SiCss3 size={35} />
								</IconContext.Provider>
							</div>
						</div>
					</div>
				</div>

			</div>
		</div>
	)
}

export default Projects