import * as React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

import * as styles from '../components/styles.module.scss'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Title from './title'
import Projects from './projects'

const IndexPage = () => (
    <Layout>
        <SEO title="Home" />
        <Title />
        <Projects />
    </Layout>
)

export default IndexPage
